.da-statistic-icon-bg {
    padding: 12px;
    max-height: 48px;
    border-radius: 50%;
    margin-right: 8px;
}

.items-widget,
.orders-widget {
    .dash-items-heading {
        font-size: 18px;
    }

    .da-bg-color-secondary-10 {
        background-color: #f32630 !important;
    }

    .da-text-color-primary-10 {
        color: #fff !important;
    }

    .da-bg-color-secondary-11 {
        background-color: #ffaf1d !important;
    }
    .da-bg-color-secondary-12 {
        background-color: #036203 !important;
    }
}

.ant-dropdown-menu-item {
    &.active-item {
        background-color: #a18e8e;
        color: #fff;
    }
}

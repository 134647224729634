.ant-alert {
  padding: @padding-sm;

  .ant-alert-icon {
    margin-right: 20px;
  }

  .ant-alert-close-icon {
    .anticon-close {
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 13px;
      height: 13px;

      svg {
        display: none;
      }
    }
  }

  &-success {
    .ant-alert-content {

      .ant-alert-message,
      .ant-alert-description {
        color: @success-1;
      }
    }

    .ant-alert-close-icon {
      .anticon-close {
        background-image: url(../../../../assets/images/alert/success-close.svg);
      }
    }
  }

  &-info {
    .ant-alert-content {

      .ant-alert-message,
      .ant-alert-description {
        color: @info-1;
      }
    }

    .ant-alert-close-icon {
      .anticon-close {
        background-image: url(../../../../assets/images/alert/info-close.svg);
      }
    }
  }

  &-warning {
    .ant-alert-content {

      .ant-alert-message,
      .ant-alert-description {
        color: @warning-1;
      }
    }

    .ant-alert-close-icon {
      .anticon-close {
        background-image: url(../../../../assets/images/alert/warning-close.svg);
      }
    }
  }

  &-error {
    .ant-alert-content {

      .ant-alert-message,
      .ant-alert-description {
        color: @danger-1;
      }
    }

    .ant-alert-close-icon {
      .anticon-close {
        background-image: url(../../../../assets/images/alert/error-close.svg);
      }
    }
  }
}
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: initial;
  height: auto;
  min-height: 40px;
}

.ant-btn>.remix-icon {
  line-height: 1;
  font-size: 16px;
}

.ant-btn-text {
  &.@{class-prefix}-btn-text-bg-none {
    background: none !important;
  }
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: @b-80  !important;
  background: @b-10  !important;
  border-color: transparent !important;
}

.ant-btn:hover {
  color: @primary-2;
  border-color: @primary-2;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: @b-0;
  background: @primary-2;
  border-color: @primary-2;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent !important;
}

.ant-btn-ghost {
  color: @primary-1;
  border-color: @primary-1;
}

.ant-btn-circle.ant-btn-sm {
  min-height: 32px;
}

.ant-btn-icon-only.ant-btn-sm {
  min-height: 32px;
}

.ant-btn-round.ant-btn-sm {
  min-height: 32px;
}

.ant-btn-sm {
  min-height: 32px;
}

.ant-btn-background-ghost.ant-btn-primary {

  &:hover,
  &:focus {
    background-color: @primary-1  !important;
    color: @b-0  !important;
  }
}
.scroll-bar {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 7px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #aaa;
    }
}

.card-thumbnail {
    width: 100%;
    height: 150px;
    background-color: #fff;

    // @
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.charactror {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 62px;
            margin: 0;
            transform: scale(1.5);
            color: #fff;
            text-transform: capitalize;
            font-weight: 600;
            // text-shadow: 1px 1px 4px #151313;
        }

        &.pattern-1 {
            background-color: #2d3436;
        }

        &.pattern-2 {
            background-color: #00b894;
        }

        &.pattern-3 {
            background-color: #0984e3;
        }

        &.pattern-4 {
            background-color: #6c5ce7;
        }

        &.pattern-5 {
            background-color: #fd79a8;

        }

        &.pattern-6 {
            background-color: #ff7675;
        }

        &.pattern-7 {
            background-color: #d63031;
        }

        &.pattern-8 {
            background-color: #e17055;
        }

        &.pattern-9 {
            background-color: #e84393;

        }

        &.pattern-10 {
            background-color: #fdcb6e;
        }
    }
}


.question-card,
.tests-card {
    .ant-card-meta-detail {
        position: relative;
        overflow: visible;

    }

    .ant-card-meta {

        h3,
        p {
            margin: 0px;
            margin-bottom: 8px;
        }

        p {
            font-size: 14px;
        }
    }

    .card-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

.parsed-question {
    // img{
    //     display: block;
    //     margin: 10px auto;
    // }
    font-size: 16px;

    .question-number {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

.tests-card {
    .ant-card-meta-detail {
        .ant-card-meta-description {
            .description {
                display: flex;

                span {
                    display: block;
                    width: 50%;

                    &:last-child {
                        text-align: right;
                    }
                }

            }
        }
    }
}

.question-edit-container {
    .ant-card-body {
        padding-top: 80px;
    }

    .right-bar {
        &.ant-card-bordered {
            border: none;
            border-left: 1.5px solid #f0f0f0;
            height: 100%;
        }

        .ant-card-body {
            padding-top: 0px;
        }
    }

    .ant-row,
    .ant-col,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
        position: initial !important;
    }

    .custom-editor-toolbar {
        opacity: 0;
        top: 0px;
        width: 100%;
        left: 0px;
        position: absolute !important;
        display: flex;
        justify-content: center;
        width: 100%;
        border-bottom: 1.2px solid #ccc;

        .ql-toolbar {
            border: none;
            // border-bottom: 1px solid;
        }

        &.element-focused {
            opacity: 1;
            z-index: 99;
        }

        &.element-blured {
            z-index: -99;
        }
    }

    .custom-editor-toolbar.default.element-blured {
        z-index: 1;
        opacity: 1;
    }

    .ant-row.choices {
        padding-left: 40px;

        // padding-right: 60px;
        &.position-relative {
            position: relative !important;

            span.list-icon {
                position: absolute;
                left: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 42px;
                top: 0px;
            }
        }
    }

    span.list-icon {
        span {
            width: 30px;
            height: 30px;
            // background: #ccc;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        position: absolute;
        left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 42px;
    }

    a.remove-icon {
        // position: absolute;
        // right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 42px;
    }

    .add-icon {
        .ant-input-number-group-addon {
            padding: 0px;

            button {
                border-radius: 7px 0px 0px 7px;
                min-height: 38px;
                border: none;
            }
        }
    }

    .ql-snow .ql-picker {
        background-color: #fff;
    }
}

.criteria-delete {
    height: 100%;
    margin-top: -10px;
}

.correct-ans-fill-in-blank {
    input {
        border-radius: 0;
        border: 1px solid #ccc;
    }
}

.csm-mock-print {
    &>* {
        font-family: "Times New Roman", Arial, Helvetica, sans-serif;
        letter-spacing: 0.4px;
    }

    &__parent {
        width: 90%;
        margin: 0 auto;
        padding: 50px 0;
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;

        &--duration-logo-container {
            position: relative;
            display: flex;
            align-items: center;
            left: 65px;
        }

        &--duration {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
            margin-left: 20px;
        }

        &--container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            position: relative;
        }

        &--print-button {
            font-weight: bold;

            @media (min-width:680px) {
                position: absolute;
                right: 15px;
                top: 15px;
            }
        }
    }

    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;

    @media (min-width : 680px) {
        grid-template-columns: 1fr 1fr;
    }

    &__option-container {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
        grid-template-columns: repeat(auto-fill, minmax(35%, 1fr));
        row-gap: 5px;
        margin-top: 5px;
    }

    &__question-image {
        margin: 10px;
    }
}

.csm-lowercase {
    text-transform: lowercase;
}

.csm-semibold {
    font-weight: 600;
}

.csm-text-center {
    text-align: center;
}

.csm-mt-10 {
    margin-top: 10px;
}

.csm-ml-10 {
    margin-left: 10px;
}

.csm-bold {
    font-weight: bold;
}

.csm-flex-end {
    display: flex;
    justify-content: flex-end;
}

// for print styling
@media print {
    .noprint {
        display: none;
    }
}


.csm-dashed_button {
    border: 2px dashed @primary-1;
}

.csm-primary-color1 {
    color: @primary-1;
}

.csm-rounded-sm {
    border-radius: 8px;
}

.csm-pointer {
    &:hover {
        cursor: pointer;
    }
}

.csm-shadow-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.csm-card-hover {
    transition: all 0.25s ease-in;
}

.csm-card-hover:hover {
    transform: translateY(-7px);
}


// custom checkbox
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-1;
    border-color: @primary-1;
}

.csm-bill-cover {
    width: 100%;
    background-color: white;
    border-radius: 7px;
    margin: 0 16px;
    margin-bottom: 32px;
}

.csm-bill-parent {
    padding: 22px 12px;
    width: 86%;
}

.csm-grade-thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.csm-reset-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.csm-editor-disabled {
    &>* {
        pointer-events: none !important;
    }

    .antd-reviewable-not-disabled {
        pointer-events: all !important;
    }
}

.cursor-disabled {
    cursor: not-allowed;
}

.csm-max-h-900 {
    max-height: 900px !important;
}

.csm-flex-col {
    flex-direction: column;
}

.csm-gap-5 {
    gap: 5px;
}

.ant-popover-buttons {
    display: flex;
    justify-content: end;
}

.subscription-product-list {
    .ant-drawer-header-title {
        width: 100%;

        .ant-drawer-title {
            width: 100%;
        }
    }
}

.email-phone {
    .ant-space-item {
        display: flex;
        align-items: center;
    }
}

.user-details-custom {
    .ant-drawer-header {
        display: none;
    }

    .user-header {
        background-color: #0f4667;
        height: 135px;
        border-bottom: 8px solid #fff;
        margin: -24px -24px 0px -24px;
        position: relative;

        .profile-pic {
            width: 150px;
            height: 150px;
            object-fit: contain;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            bottom: -45px;
            border: 8px solid #fff;
            left: 40px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .letter-avatar {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 60px;
                background-color: #c75d7f;
                color: #fff;
                font-weight: 800;
            }
        }

        .profile-info {
            position: absolute;
            bottom: 15px;
            left: 205px;

            h2,
            .ant-space {
                color: #fff;
                margin: 0;
            }

            .ant-space:last-child {
                margin-left: 10px;
            }
        }
    }

    .ant-drawer-body {
        background-color: #fff;
    }

    .ant-drawer-footer {
        div {
            margin: -24px;
            background-color: #bdddf142;
            padding: 24px;
        }
    }

    .user-details {
        margin-top: 54px;

        .blocks {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .block-item {
                width: calc(50% - 20px);
                max-height: 150px;
                background-color: #bdddf142;
                margin: 10px;
                border-radius: 10px;
                padding: 20px 10px;

                p {
                    margin: 0;
                    color: #05345c;

                    &.value {
                        font-weight: 600;
                        font-size: 16px;
                        color: #17334a;
                    }
                }
            }
        }
    }

    .user-subscriptions {
        width: 100%;
    }

    .ant-list-vertical .ant-list-item-meta {
        margin-bottom: 0;
    }

    .subscription-avatar {
        width: 50px;
        height: 50px;
        font-size: 24px;
        color: #fff;
        background-color: #c00;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: 800;

        &.pattern-1 {
            background-color: #2d3436;
        }

        &.pattern-2 {
            background-color: #00b894;
        }

        &.pattern-3 {
            background-color: #0984e3;
        }

        &.pattern-4 {
            background-color: #6c5ce7;
        }

        &.pattern-5 {
            background-color: #fd79a8;

        }

        &.pattern-6 {
            background-color: #ff7675;
        }

        &.pattern-7 {
            background-color: #d63031;
        }

        &.pattern-8 {
            background-color: #e17055;
        }

        &.pattern-9 {
            background-color: #e84393;

        }

        &.pattern-10 {
            background-color: #fdcb6e;
        }
    }

    .subscription-list-item {
        cursor: pointer;
    }
}

.lessons-tabs {
    .ant-tabs-nav {
        background: #fff;
        margin: 0;
        padding: 0px 10px;
    }

    .ant-tabs-tab-btn {
        font-size: 14px;
        font-weight: 500;
    }
}

.app-customization {
    .dynamic-delete-button {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 24px;
        cursor: pointer;
    }

    .dynamic-form-items {
        position: relative;
        padding-right: 50px;
    }


}

.question-edit-container {
    &.lesson-edit-container {
        height: calc(100vh - 260px);
        overflow: hidden;

        .question-text-box {
            background-color: #fff;
        }

        .quill {
            height: calc(100vh - 414px);
            .scroll-bar()
        }

        .ql-container.ql-snow {
            border: none;
            border-bottom: 1px solid #ccc;
        }

        .ant-card-body {
            padding-top: 65px;
            height: 100%;
            overflow-y: auto;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            overflow: hidden;

            .custom-editor-toolbar {
                background-color: #fff;
            }
        }

        .article-type-question {
            position: absolute !important;
            bottom: 0;
            background: #fff;
            width: 100%;
            padding: 0;
            margin-left: 8px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 16px;
            overflow: hidden;
            min-height: 43px;

            >.ant-row.ant-row-end {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .ant-row.ant-form-item {
                    margin: 0px;

                    &.da-mr-10 {
                        margin: 0px;
                    }
                }
            }
        }
    }

    &.lesson-edit-pdf {
        .ant-card-body {
            padding-top: 24px;
        }

        .video-container {
            display: none;
            position: relative;

            // .ant-spin {
            //     height: 320px;
            //     width: 100%;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     position: absolute;
            //     background: #cccccc2e;
            // }

            &.active {
                display: block;
            }

            &.hide-iframe {
                iframe {
                    opacity: 0;
                }
            }
        }
    }

    // div.lesson-pdf-warpper {
    //     margin-top: -65px;
    //     overflow-y: auto;
    //     max-height: calc(100% - 75px);
    //     .scroll-bar()
    // }
}

.coverloader {
    width: 100%;
    height: 100%;
    background: #44434336;
    position: absolute;
    /* margin-right: 48px; */
    /* margin-bottom: -24px; */
    top: 0;
    left: 0;
    padding-top: 80px;
    z-index: 1;

}

.integration-card {
    min-height: 250px;
    height: 100%;

    h6 {
        font-size: 14px;
        min-height: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.email {
        img {
            width: auto;
            height: 55px;
        }
    }

    &.full-size {
        img {
            max-width: 140px;
        }

        h6 {
            min-height: auto;
        }
    }

    .integration-info {
        padding-bottom: 10px;
    }

    .view-button {
        position: absolute;
        bottom: 10px;
        display: block;
        width: calc(100% - 48px);
    }
}

// .da-content-main {
//     height: calc(100vh - 173px);
// }

.align-elemnts-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-plan {
    margin-bottom: -48px;

    .remove-icon {

        margin-top: -24px;
    }
}

.lesson-details {
    .ant-drawer-close {
        transform: none
    }

    .ant-drawer-body {
        padding: 0px;
    }

    .thumnail-container {
        min-height: 200px;
        max-height: 300px;
        background-color: #fff;
        position: relative;

        .card-thumbnail {
            min-height: inherit;
            max-height: inherit;
        }

        img {
            width: 100%;
            max-height: 100%;
            max-height: inherit;
            object-fit: cover;
        }

        .change-image {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 60px;
            height: 60px;
            // display: none;
        }

        &:hover {
            .change-image {
                display: block;
            }
        }
    }

    .lesson-info-container {
        padding: 24px;
        box-shadow: -1px 0px 5px #ccc;

        .action-btn {
            width: 100px;
        }

        .da-caption {
            color: #020326 !important;
            font-size: 14px !important;
            font-weight: 500 !important;
        }
    }
}

.row-dragging {
    z-index: 9999 !important;
}

.batch-students-list {
    .ant-modal-body {
        padding: 0px;
    }

    .ant-table-wrapper {
        padding: 24px !important;
    }
}

.video-temp-preview {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f7f7f7;
    border: 0px;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 98px;
    color: #6d6d6d;
}

.live-lesson-form {
    .ant-input[disabled] {
        color: #282828;
        background-color: #ffffff;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: #282828;
        background-color: #ffffff;
    }

    .ant-picker.ant-picker-disabled {
        background-color: #ffffff;
    }

    .ant-picker-input>input[disabled] {
        color: #282828;
        background-color: #ffffff;
    }
}

.assign-course {
    border-bottom: 2px solid #ccc;
    padding-bottom: 20px;

    p.heading {
        font-size: 16px;
        font-weight: 600;

    }

    .assign-slection-btn {
        border: 2px dashed #1b6586;
        padding: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 10px;
        transition: .5s;
        cursor: pointer;

        &:hover {
            transition: 0.5s;
            border-color: #ffffff;
            background-color: #00b894;
            font-weight: 500;
            color: #fff;
        }

        p {
            margin: 0px;
        }
    }
}

.reported-questions {
    background-color: #bdddf142;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title-parent {
        display: flex;
        justify-content: space-between;
    }

    &--selection {
        background-color: #c1f4ff;
    }

    &--big-key {
        color: #05345c;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 8px;
    }

    &--key {
        color: #05345c;
        font-weight: 600;
    }

    &--value {
        color: #17334a;
    }
}

;

.color-dark-blue {
    color: #17334a;
}

.text-small {
    font-size: 12px;
}

.sidebar__footer {
    display: flex;
    padding: 15px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.csm-bold-1 {
    font-size: 20px;
    font-weight: bold;
}

.csm-letter-spacing-1 {
    letter-spacing: 1.2px;
}

.csm-lesson-already-videos {
    width: 100%;
    height: 100%;
    background-color: #f0f3f5;
    border-radius: 10px;
    padding: 10px 14px;
    cursor: pointer;
}


.csm-lesson-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.csm-redirection:link,
.csm-redirection:visited {
    color: #25316D;
    font-weight: 600;
}

.csm-test-textArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.csm-img-logo {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: start;
    justify-content: center;
}

.csm-sidemenu-button {
    justify-self: end;
    justify-items: end;
    margin-left: auto;
}

.csm-analytics-color {
    color: red;
    font-size: 15px;
}

.csm-segment-overflow {
    overflow-x: scroll;
    overflow-x: hidden;
}

.csm-plan-checkbox {
    display: flex;

    justify-content: center;
    align-items: center;
}

.da-sidebar .ant-menu-sub.ant-menu-inline>.ant-menu-item.sidebar-submenu {
    padding-left: 56px !important
}

.@{class-prefix}-welcomeCard {
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 100%;

    h1 {
        color: #0984e3;
    }

    h3 {
        color: #f70000;
        font-weight: 600;
    }

    .@{class-prefix}-dot-1 {
        width: 250px;
        height: 250px;
        position: absolute;
        z-index: -1;
        opacity: 0.5;
        top: -58%;
        left: -41%;
        border-radius: 50%;

        @media @lg {
            left: -20%;
        }

        @media @xs {
            left: -40%;
        }
    }

    .@{class-prefix}-img-1 {
        position: absolute;
        top: 50%;
        transform: translate(30%, -50%);
        right: 0;

        @media @lg {
            display: none;
        }

        @media @sm {
            display: none;
        }

        @media @xs {
            display: none;
        }
    }

    .@{class-prefix}-img-2 {
        position: absolute;
        top: 50%;
        transform: translate(30%, -50%);
        right: 0;

        @media @xs {
            display: none;
        }
    }

    .@{class-prefix}-img-3 {
        position: absolute;
        top: 50%;
        transform: translate(30%, -50%);
        right: 0;

        @media @xs {
            display: none;
        }
    }

    .@{class-prefix}-img-4 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -14%;

        @media @lg {
            right: -10%;
        }

        @media @xs {
            display: none;
        }
    }

    .@{class-prefix}-img-5 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -14%;

        @media @lg {
            right: -10%;
        }

        @media @xs {
            display: none;
        }
    }
}

.ant-modal.batch-students-list {

    .ant-modal-close-x {
        height: 72px;
    }
}

.recurring-weeks {
    .ant-checkbox-group {

        .ant-checkbox-wrapper {
            display: flex;
            margin: 14px 0px;

            .ant-checkbox+span {
                padding-left: 16px;
                font-size: 15px;
            }
        }
    }
}

.multiple-dates {
    // &.rmdp-wrapper.rmdp-shadow {
    //     width: 100%;
    //     .rmdp-calendar{
    //         width: 80%;
    //     }
    //     .rmdp-day-picker{
    //         justify-content: center;
    //     }
    // }

    .rmdp-week-day {
        color: @primary-1;
    }

    .rmdp-day.rmdp-selected {
        span:not(.highlight) {

            background-color: @primary-1;
        }
    }

    .rmdp-panel-body span {
        font-size: 12px;
        line-height: 20px;
        margin: 0 5px;
    }

    .rmdp-panel-body li,
    .rmdp-panel-body li.bg-blue {
        background-color: @primary-1;

        .b-deselect {
            color: @primary-1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            line-height: 0;
        }
    }
}

.popover-confirm-extra-button {
    display: block;
    min-width: 323px;

    .cst-popover-buttons {
        display: none;
        position: absolute;
        left: 0;
        top: 37px;
    }
}

.subscription-plans-row {
    .ant-row.ant-form-item {
        margin-bottom: 0px;
    }
}

.mock-test-settings {
    h5 {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        background-color: #cccccc30;
        padding-top: 5px;
        padding-left: 5px;
    }

    .ant-radio-inner::after {
        top: 2.2px;
    }
}

.mock-test-settings-tabs-container {
    position: relative;

    .back__btn {
        position: absolute;
        top: 0;
        left: 0;
    }

    .mock-test-settings-tabs {
        .ant-tabs-nav {
            margin-left: 45px;
        }
    }
}

.question-bank-error-file {
    .ant-modal-body {
        padding: 0px 24px;
    }
}

@media print {
    .question-bank-error-file {
        width: calc(100vw - 15px) !important;
        top: 0px;
        max-width: 100vw;
        padding: 0;

        .ant-modal-content {
            border-radius: 0px;

            .ant-modal-close {
                display: none;
            }

            .ant-modal-header {
                border-radius: 0px;
                display: none;
            }

            .ant-modal-footer {
                display: none;
            }
        }
    }
}

.ant-modal.question-bank-error-file {
    top: 10px;


}

.uploaded-question-preview {
    padding: 10px 10px 10px 100px;

    .error-element {
        background-image: linear-gradient(to right, #e20d0d 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 10px 2.5px;
        background-repeat: repeat-x;
    }
    strong.question-number{
            font-size: 20px;
    }
}

.uploaded-question-preview .question {
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.uploaded-question-preview .question-data {
    position: relative;
    margin: 0 0 16px 0;
}

.uploaded-question-preview span.preview-error {
    display: block;
    color: #f00;
    font-weight: 800;
    position: absolute;
    left: -60px;
    top: 0px;
    font-size: 18px;
}

.uploaded-question-preview .question:hover span.preview-error span.message {
    opacity: 1;
    transition: 0.3s;
}

.uploaded-question-preview .question-data:hover span.preview-error span.message {
    opacity: 1;
    transition: 0.3s;
}

.uploaded-question-preview span.preview-error span.message {
    display: block;
    margin-top: 10px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    max-width: 400px;
    font-weight: 300;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    min-width: 86px;
    opacity: 0;
    transition: 0.3s;
}
.csm-form-label--full label {
    width: 100% !important;
}
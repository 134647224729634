.ant-menu-item {
  display: flex;
  align-items: center;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-item-only-child {
  padding-left: 56px;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
  text-align: left !important;
}

.ant-menu-vertical .ant-menu-item {
  text-align: center;
}

.ant-menu-submenu-arrow {
  width: 24px;
}

.ant-menu-horizontal .ant-menu-item svg {
  vertical-align: middle;
  margin-top: -2px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-menu-submenu-arrow {
  right: 0;
}

// RTL
html[dir="rtl"] {

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-right: 10px;
    margin-left: 0;
  }
}